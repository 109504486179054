<template>
  <div class="cont-box">
    <img src="../../assets/c-bg-top.png">
    <img src="../../assets/c-bg-bottom.png" class="img01">
    <div class="cont">
      <div class="header">
        <img class="logo" src="../../assets/c-logo.png">
        <p class="title">到访信息</p>
      </div>
      <div class="item">
        <ul class="list">
          <li><label>姓名:</label>
            <p>{{subData.username}}</p>
          </li>
          <li><label>手机号:</label>
            <p>{{subData.phone}}</p>
          </li>
          <li><label>来访时间起:</label>
            <p>{{subData.visitTimeStart}}</p>
          </li>
          <li><label>来访时间止:</label>
            <p>{{subData.visitTimeEnd}}</p>
          </li>
          <li><label>来访事由:</label>
            <p>{{subData.reason}}</p>
          </li>
          <li><label>来访人数:</label>
            <p>{{subData.visitorNum}}</p>
          </li>
        </ul>
        <div class="list">
          <p class="code-title">通行码</p>
          <div id="qrCode" ref="qrCodeDiv"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import axios from 'axios'
  import QRCode from 'qrcodejs2'
  export default {
    data() {
      return {
        axios: axios,
        sexArr: [{
            name: '男',
            value: '1'
          },
          {
            name: '女',
            value: '2'
          },
          {
            name: '保密',
            value: '0'
          }
        ],
        gsArr: [{
            name: '科大智能电气',
            value: 'DQ'
          },
          {
            name: '科大智能科技',
            value: 'KJ'
          },
          {
            name: '科大智能机器人',
            value: 'JQR'
          },
          {
            name: '聚川电子',
            value: 'JC'
          },
          {
            name: '智能物流',
            value: 'WL'
          },
          {
            name: '其它',
            value: 'QT'
          }
        ],
        subData: {

        }
      }
    },
    mounted() {
      const id = this.$route.query.id
      this.getList(id)
    },
    methods: {
      bindQRCode() {
        new QRCode(this.$refs.qrCodeDiv, {
          text: this.subData.code,
          width: 150,
          height: 150,
          colorDark: this.subData.status === '0' ? '#67c23a': '#f56c6c', //二维码颜色
          colorLight: "#ffffff", //二维码背景色
          correctLevel: QRCode.CorrectLevel.L //容错率，L/M/H
        })
      },
      getList(id) {
        this.$axios.post('/visitor-record/findListById', {
          idString: id
        }).then(res => {
          this.subData = res.data.data
          this.$nextTick(function() {
            this.bindQRCode()
          })
        }).catch(err => {

        })
      },
    }
  }
</script>

<style scoped>
  .cont-box {
    position: relative;
    min-height: 100%;
  }

  .cont-box>img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: -1;
  }

  .cont-box>img.img01 {
    bottom: 0;
    top: auto;
  }


  .header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-items: center;
  }

  .logo {
    width: 40%;
    padding: 1.5rem 0 1rem;
  }

  .title {
    font-size: 1.6rem;
    margin-bottom: 2rem;
    font-weight: bold;
    text-align: center;
    width: 60%;
  }

  .title-d {
    color: #e8380d;
    padding: 0.8rem 0;
  }

  .item {
    color: #646464;
    font-size: 28rpx;
    border-radius: 3px;
    margin: 10px 20px 20px;
    background-color: transparent;
  }

  .list {
    padding: 1.2rem;
    margin-bottom: 2rem;
    background-color: #fff;
    box-shadow: 0 2px 4px #dedede;
    border: 1px solid #F9F9F9;
  }

  .list>li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 4px 0;
  }

  .list>li>label {
    margin-right: 8px;
  }

  .list>li>p {
    color: #323232;
  }

  .code-title {
    font-size: 1.1rem;
    font-weight: bold;
    text-align: center;
  }
  
  #qrCode {
    padding: 1.2rem 0 1rem;
    display: flex;
    justify-content: center;
  }
</style>
